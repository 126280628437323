import React, {useEffect, useRef} from "react"

const FullWidthVideo = ({
  mp4,
  webm,
  className,
}) => {
  const refVideo = useRef(null);

  useEffect(() => {
    if (!refVideo.current) {
        return;
    }

    refVideo.current.defaultMuted = true;
    refVideo.current.muted = true;
}, [refVideo]);

  return (
    <video ref={refVideo} className={`w-full h-auto ${className}`} loop autoPlay muted={true} playsInline>
      <source src={webm} type="video/webm"></source>
      <source src={mp4} type="video/mp4"></source>
    </video>
  )
}

export default FullWidthVideo
