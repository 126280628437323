import React, { useEffect, createRef } from "react";
import lottie from "lottie-web";

const Lottie = ({
  file, 
  className = '',
  loop = true,
  autoplay = true
}) => {
  let animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: loop,
      autoplay: autoplay,
      animationData: file
    });
    return () => anim.destroy();
  }, []);

  return (
    <div className={`w-full mx-auto ${className}`} ref={animationContainer} />
  );
};

export default Lottie;