import React from "react";
import Slider from "react-slick";

import Headshot from "../Headshot/Headshot"
import markmp4 from "../../images/team/Marc.mp4"
import markwebm from "../../images/team/Marc.webm"
import kristenmp4 from "../../images/team/Kristen.mp4"
import kristenwebm from "../../images/team/Kristen.webm"
import jennymp4 from "../../images/team/Jenny.mp4"
import jennywebm from "../../images/team/Jenny.webm"
import nicholasmp4 from "../../images/team/Nicholas.mp4"
import nicholaswebm from "../../images/team/Nicholas.webm"
import carouselArrow from "../../images/carousel_arrow.svg"

const homepage_team = [
  {
    name : 'Mark Soares',
    title : 'Founder',
    webm : markwebm,
    mp4 : markmp4
  },
  {
    name : 'Kristen DiVenti',
    title : 'Executive Assistant',
    webm : kristenmp4,
    mp4 : kristenwebm
  },
  {
    name : 'Nick Garcia',
    title : 'Specialist, Social Media',
    webm : nicholaswebm,
    mp4 : nicholasmp4
  },
  {
    name : 'Jenny Mauric',
    title : 'Creative Director',
    webm : jennymp4,
    mp4 : jennywebm
  }
]

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={carouselArrow} className={'arrow-icon'} alt='Next slide' />
    </button>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={carouselArrow} className={'arrow-icon'} alt='Previous slide' />
    </button>
  );
}

const ExecutiveCarousel = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerPadding: '40px',
    mobileFirst: true,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Slider className={`w-full`} {...settings}>
      {homepage_team.map((member, index) => {
        return (
          <div className={`pl-2 pr-3`} key={index}>
            <Headshot 
              name={member.name}
              title={member.title}
              webm={member.webm}
              mp4={member.mp4}
              key={index}
            />
          </div>
        )
      })}
    </Slider>
  );
}

export default ExecutiveCarousel